import {Controller, useForm} from 'react-hook-form'
import text from '../../data/text.json'
import {InputText} from 'primereact/inputtext'
import {Calendar} from 'primereact/calendar'
import {Dropdown} from 'primereact/dropdown'
import Select from '../../api/select'
import {useEffect, useState} from 'react'
import {Button} from 'primereact/button'
import moment from 'moment'

const Filter = ({fetchUsers}) => {
    const lang = 'az'
    const [duties, setDuties] = useState([])
    const [loader, setLoader] = useState(false)

    const prefixElement = prefix => {
        return {
            value: prefix,
            label: prefix
        }
    }
    const prefixes = [
        prefixElement('050'),
        prefixElement('051'),
        prefixElement('055'),
        prefixElement('070'),
        prefixElement('077'),
        prefixElement('099'),
        prefixElement('010')
    ]

    const roles = [
        {
            value: 1,
            label: text[lang].admin
        },
        {
            value: 2,
            label: text[lang].doctor
        },
        {
            value: 3,
            label: text[lang].reception
        },
        {
            value: 4,
            label: text[lang].admin_and_doctor
        }
    ]

    const statuses = [
        {
            value: 1,
            label: text[lang].active
        },
        {
            value: 0,
            label: text[lang].deactive
        }
    ]

    const {control, handleSubmit, reset} = useForm()

    const filter = async data => {
        setLoader(true)
        for (let d in data) {
            if (data[d] === '' || data[d] === undefined) {
                data[d] = null
            }
        }
        await fetchUsers({
            ...data,
            birth: data?.birth ? moment(data?.birth).format('YYYY-MM-DD') : null
        })
        setLoader(false)
    }

    const fetchDuties = async() => {
        const data = await Select.getDuties()
        setDuties(data)
    }

    useEffect(() => {
        fetchDuties()
    }, [])

    return (
        <form onSubmit={handleSubmit(filter)} className="grid">
            <div className="col-12 md:col-4">
                <Controller render={({field: {onChange, value}}) => (
                    <>
                        <label htmlFor="name">{text[lang].name}</label>
                        <InputText
                            className="w-full"
                            value={value}
                            onChange={onChange}
                            id="name"
                            aria-describedby="name"
                            placeholder={text[lang].name}
                        />
                    </>
                )} name="name" control={control}/>
            </div>
            <div className="col-12 md:col-4">
                <Controller render={({field: {onChange, value}}) => (
                    <>
                        <label htmlFor="surname">{text[lang].surname}</label>
                        <InputText
                            className="w-full"
                            value={value}
                            onChange={onChange}
                            id="surname"
                            aria-describedby="surname"
                            placeholder={text[lang].surname}
                        />
                    </>
                )} name="surname" control={control}/>
            </div>
            <div className="col-12 md:col-4">
                <Controller render={({field: {onChange, value}}) => (
                    <>
                        <label htmlFor="father">{text[lang].father_name}</label>
                        <InputText
                            className="w-full"
                            value={value}
                            onChange={onChange}
                            id="father"
                            aria-describedby="father"
                            placeholder={text[lang].father_name}
                        />
                    </>
                )} name="father" control={control}/>
            </div>
            <div className="col-12 md:col-4">
                <Controller render={({field: {onChange, value}}) => (
                    <>
                        <label htmlFor="birth">{text[lang].birth_date}</label>
                        <Calendar
                            locale={lang}
                            dateFormat="dd/mm/yy"
                            placeholder={text[lang].birth_date}
                            className="w-full" value={value}
                            onChange={(e) => onChange(e.value)}/>
                    </>
                )} name="birth" control={control}/>
            </div>
            <div className="col-12 md:col-4">
                <Controller render={({field: {onChange, value}}) => (
                    <>
                        <label htmlFor="email">{text[lang].email}</label>
                        <InputText
                            className="w-full"
                            value={value}
                            onChange={onChange}
                            id="email"
                            aria-describedby="email"
                            placeholder={text[lang].email}
                        />
                    </>
                )} name="email" control={control}/>
            </div>
            <div className="col-12 md:col-4">
                <label htmlFor="phone_prefix">{text[lang].mobile_phone}</label>
                <div className="w-full flex align-items-start gap-1">
                    <Controller control={control} render={({field: {value, onChange}}) => (
                        <Dropdown
                            value={value}
                            onChange={onChange}
                            options={prefixes}
                            name="phone_prefix"
                            className="w-14"/>
                    )} name="phone_prefix"/>
                    <div className="w-full">
                        <Controller render={({field: {value, onChange}}) => (
                            <InputText
                                className="w-full"
                                value={value}
                                onChange={onChange}
                                id="phone"
                                aria-describedby="phone"
                                placeholder={text[lang].mobile_phone}
                            />
                        )} name="phone" control={control}/>
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-4">
                <Controller render={({field: {onChange, value}}) => (
                    <>
                        <label htmlFor="duty_id">{text[lang].position}</label>
                        <Dropdown
                            value={value}
                            onChange={onChange}
                            options={duties}
                            className="w-full"
                            name="duty_id"/>
                    </>
                )} name="duty_id" control={control}/>
            </div>
            <div className="col-12 md:col-4">
                <Controller render={({field: {onChange, value}}) => (
                    <>
                        <label htmlFor="role">{text[lang].role}</label>
                        <Dropdown
                            value={value}
                            onChange={onChange}
                            options={roles}
                            className="w-full"
                            name="role"/>
                    </>
                )} name="role" control={control}/>
            </div>
            <div className="col-12 md:col-4">
                <Controller render={({field: {onChange, value}}) => (
                    <>
                        <label htmlFor="status">{text[lang].status}</label>
                        <Dropdown
                            value={value}
                            onChange={onChange}
                            options={statuses}
                            className="w-full"
                            name="status"/>
                    </>
                )} name="status" control={control}/>
            </div>
            <div className="col-12">
                <div className="flex justify-content-end gap-1">
                    <Button className="p-button-outlined d-flex align-items-center gap-1" onClick={() => reset()}>
                        <i className="pi pi-replay mr-1"/>
                    </Button>
                    <Button className="p-button-danger d-flex align-items-center gap-1" disabled={loader}>
                        {loader && <i className="pi pi-spin pi-spinner mr-1"/>}
                        {text[lang].search}
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default Filter
