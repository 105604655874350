import axios from './index'

class Select {
    getDuties = () => axios.get('/duty/select')
    getDoctors = () => axios.get('/doctors/select', {
        params: {
            check_role: 1
        }
    })
}

export default new Select()
