import {useEffect, useState} from 'react'
import {ProgressSpinner} from 'primereact/progressspinner'
import text from '../../../data/text.json'
import Users from '../../../api/users'
import Avatar from '../../../components/core/avatar'
import {Column} from 'primereact/column'
import {DataTable} from 'primereact/datatable'
import {Badge} from 'primereact/badge'
import Filter from './Filter'
import {Paginator} from 'primereact/paginator'

const Doctors = () => {
    const lang = 'az'

    const [isFetching, setIsFetching] = useState(false)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [paginatorData, setPaginatorData] = useState({
        first: 0,
        rows: 10,
        page: 0
    })
    const fetchData = async(params = null) => {
        setIsFetching(true)
        try {
            const res = await Users.doctorReports({
                ...params,
                page: paginatorData.page + 1
            })
            setData(res?.data?.map(item => {
                return {
                    ...item,
                    doctor: <div className="flex gap-1 align-items-center">
                        <div style={{minWidth: '72px'}}>
                            <Avatar user={item}/>
                        </div>
                        <span>{item.name} {item.surname}</span>
                    </div>,
                    total: <Badge value={item?.total} severity="primary"/>,
                    pending: <Badge value={item?.pending} severity="warning"/>,
                    received: <Badge value={item?.received} severity="success"/>,
                    patient_not_participate: <Badge value={item?.patient_not_participate} severity="danger"/>,
                    doctor_not_participate: <Badge value={item?.doctor_not_participate} severity="danger"/>
                }
            }))
            setTotal(res?.total)
        } catch (e) {
            console.log(e)
        } finally {
            setIsFetching(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [paginatorData.page])

    return (
        <div className="statistics-page w-full">
            {isFetching ? (
                <div className="flex mt-5 justify-content-center align-items-center">
                    <ProgressSpinner/>
                </div>
            ) : (
                <div className="content w-full">
                    <p className="page-title">{text[lang].doctor_statistics_page_title} - {total}</p>
                    <Filter fetchData={fetchData}/>
                    <DataTable emptyMessage={text[lang].no_info}
                        className="mt-5" value={data}>
                        <Column field="doctor" header={text[lang].doctor} sortable/>
                        <Column field="total" header={text[lang].total} sortable/>
                        <Column field="received" header={text[lang].accept} sortable/>
                        <Column field="pending" header={text[lang].pending} sortable/>
                        <Column field="patient_not_participate" header={text[lang].patient_not_participate} sortable/>
                        <Column field="doctor_not_participate" header={text[lang].doctor_not_participate} sortable/>
                    </DataTable>
                    <Paginator first={paginatorData.first} rows={paginatorData.rows}
                        totalRecords={total}
                        onPageChange={setPaginatorData}
                    />
                </div>
            )}
        </div>
    )
}

export default Doctors
