import text from '../../data/text.json'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import {useEffect, useState} from 'react'
import WorkerForm from './WorkerForm'
import {Dialog} from 'primereact/dialog'
import Users from '../../api/users'
import moment from 'moment'
import {Badge} from 'primereact/badge'
import UpdatePassword from './UpdatePassword'
import Filter from './Filter'
import {ProgressSpinner} from 'primereact/progressspinner'
import Avatar from '../../components/core/avatar'

const Workers = () => {
    const [formIsActive, setFormIsActive] = useState(null)
    const [passwordForm, setPasswordForm] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [users, setUsers] = useState([])
    const lang = 'az'

    const updateStatus = async id => {
        setIsFetching(true)
        try {
            await Users.updateStatus({id})
            fetchUsers()
        } catch (e) {
            console.log('Error')
        } finally {
            setIsFetching(false)
        }
    }

    const noInfoText = text[lang].no_info

    const fetchUsers = async(params = null) => {
        setIsFetching(true)
        const data = await Users.getList(params)
        setUsers(data.map(item => {
            return {
                ...item,
                full_name: <div className="flex gap-1 align-items-center">
                    <div style={{minWidth: '72px'}}>
                        <Avatar user={item}/>
                    </div>
                    <span>{item.name} {item.surname}</span>
                </div>,
                email: <a href={`mailto:${item.email}`}>{item.email}</a>,
                father: item?.father || <Badge value={noInfoText} severity="secondary"/>,
                birth: item?.birth ? moment(item?.birth).format('DD/MM/YYYY') :
                    <Badge value={noInfoText} severity="secondary"/>,
                phone: item?.phone ?
                    <a href={`tel:${item.phone_prefix} ${item.phone}`}>{item.phone_prefix} {item.phone}</a> :
                    <Badge value={noInfoText} severity="secondary"/>,
                status: <Badge style={{cursor: 'pointer'}} onClick={() => updateStatus(item?.id)}
                    severity={item.status ? 'success' : 'danger'}
                    value={item.status ? text[lang].active : text[lang].deactive}/>,
                duty_label: item?.duty_label || <Badge value={noInfoText} severity="secondary"/>,
                role_label: item?.role_label || <Badge value={noInfoText} severity="secondary"/>,
                buttons: <div className="flex gap-1">
                    <Button className="p-button-success p-button-sm" onClick={() => setFormIsActive(item)}>
                        <i className="pi pi-pencil"/>
                    </Button>
                    <Button className="p-button-primary p-button-sm" onClick={() => setPasswordForm(item)}>
                        <i className="pi pi-lock"/>
                    </Button>
                </div>
            }
        }))
        setIsFetching(false)
    }

    useEffect(() => {
        fetchUsers()
    }, [])

    return (
        <div className="worker-page w-full">
            <Dialog
                header={text[lang].new_worker}
                visible={formIsActive}
                draggable={false}
                onHide={() => setFormIsActive(null)}
                style={{width: '50vw'}} breakpoints={{'960px': '75vw', '641px': '100vw'}}>
                <WorkerForm setModal={setFormIsActive} modal={formIsActive} fetchUsers={fetchUsers}/>
            </Dialog>
            <Dialog
                header={text[lang].change_password}
                visible={passwordForm}
                draggable={false}
                onHide={() => setPasswordForm(null)}
                style={{width: '50vw'}} breakpoints={{'960px': '75vw', '641px': '100vw'}}>
                <UpdatePassword setModal={setPasswordForm} modal={passwordForm} fetchUsers={fetchUsers}/>
            </Dialog>
            <div className="content w-full">
                <div className="flex justify-content-between">
                    <p className="page-title">{text[lang].workers_page_title}</p>
                    <Button className="p-button-danger" onClick={() => setFormIsActive(true)}>
                        <span className="flex align-items-center gap-1" icon>
                            <i className="pi pi-plus" style={{fontSize: '10px'}}/>
                            <span>{text[lang].new_worker}</span>
                        </span>
                    </Button>
                </div>
                <Filter fetchUsers={fetchUsers}/>
                {isFetching ? (
                    <div className="flex justify-content-center mt-5 align-items-center">
                        <ProgressSpinner/>
                    </div>
                ) : (
                    <DataTable paginator rows={10} rowsPerPageOptions={[10, 20, 30]} emptyMessage={text[lang].no_info}
                        className="mt-5" value={users}>
                        <Column field="full_name" header={text[lang].full_name} sortable/>
                        <Column field="father" header={text[lang].father_name} sortable/>
                        <Column field="birth" header={text[lang].birth_date} sortable/>
                        <Column field="email" header={text[lang].email}/>
                        <Column field="phone" header={text[lang].mobile_phone}/>
                        <Column field="duty_label" header={text[lang].position} sortable/>
                        <Column field="role_label" header={text[lang].role} sortable/>
                        <Column field="status" header={text[lang].status}/>
                        <Column field="buttons"/>
                    </DataTable>
                )}
            </div>
        </div>
    )
}

export default Workers
